// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-data-pages-index-jsx": () => import("./../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-aviso-legal-index-jsx": () => import("./../src/data/pages/aviso-legal/index.jsx" /* webpackChunkName: "component---src-data-pages-aviso-legal-index-jsx" */),
  "component---src-data-pages-404-index-tsx": () => import("./../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-balsamo-multi-reparador-apaziguante-index-jsx": () => import("./../src/data/pages/balsamo-multi-reparador-apaziguante/index.jsx" /* webpackChunkName: "component---src-data-pages-balsamo-multi-reparador-apaziguante-index-jsx" */),
  "component---src-data-pages-como-funciona-index-jsx": () => import("./../src/data/pages/como-funciona/index.jsx" /* webpackChunkName: "component---src-data-pages-como-funciona-index-jsx" */),
  "component---src-data-pages-contactos-index-jsx": () => import("./../src/data/pages/contactos/index.jsx" /* webpackChunkName: "component---src-data-pages-contactos-index-jsx" */),
  "component---src-data-pages-cookie-policy-index-jsx": () => import("./../src/data/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-cookie-policy-index-jsx" */),
  "component---src-data-pages-eritrema-solar-index-jsx": () => import("./../src/data/pages/eritrema-solar/index.jsx" /* webpackChunkName: "component---src-data-pages-eritrema-solar-index-jsx" */),
  "component---src-data-pages-feridas-cutaneas-index-jsx": () => import("./../src/data/pages/feridas-cutaneas/index.jsx" /* webpackChunkName: "component---src-data-pages-feridas-cutaneas-index-jsx" */),
  "component---src-data-pages-mapa-do-site-index-jsx": () => import("./../src/data/pages/mapa-do-site/index.jsx" /* webpackChunkName: "component---src-data-pages-mapa-do-site-index-jsx" */),
  "component---src-data-pages-o-que-e-biafine-index-jsx": () => import("./../src/data/pages/o-que-e-biafine/index.jsx" /* webpackChunkName: "component---src-data-pages-o-que-e-biafine-index-jsx" */),
  "component---src-data-pages-politica-de-privacidade-index-jsx": () => import("./../src/data/pages/politica-de-privacidade/index.jsx" /* webpackChunkName: "component---src-data-pages-politica-de-privacidade-index-jsx" */),
  "component---src-data-pages-quando-e-como-aplicar-index-jsx": () => import("./../src/data/pages/quando-e-como-aplicar/index.jsx" /* webpackChunkName: "component---src-data-pages-quando-e-como-aplicar-index-jsx" */),
  "component---src-data-pages-queimaduras-do-1-grau-index-jsx": () => import("./../src/data/pages/queimaduras-do-1-grau/index.jsx" /* webpackChunkName: "component---src-data-pages-queimaduras-do-1-grau-index-jsx" */)
}

