module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BodilessJS","short_name":"Bodiless","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7b6e8bf81b3821be4de9c13bef7a0abd"},
    },{
      plugin: require('../node_modules/@bodiless/gatsby-theme-bodiless/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@canvasx/gatsby-plugin-onetrust/gatsby-browser.js'),
      options: {"plugins":[],"mainScriptDisabled":true,"scriptUrl":"//optanon.blob.core.windows.net/consent/83c40913-6598-49a2-b549-8eb9fb5307b8-test.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
